import React from "react";
import "./menubar.scss";

import { TweenMax as TM, Power2, Power3 } from "gsap/all";
import { ContextProviderComponent } from "../contextProviderComponent";
import OnboardingModalComponent from "../onboardingModalComponent/onboardingModalComponent";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { InputLabel } from "@material-ui/core";

class MenuBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageData: {
        item1: { text: "" },
        item2: { text: "" },
        item3: { text: "" },
        item4: { text: "" },
        item5: { text: "" },
        item6: { text: "" },
        item7: { text: "" },
        button: { text: "" },
        products: [],
      },
      showOnboardingModal: false,
      activeDocMeta: {
        lang: "",
        alternateLanguages: [{ lang: "", type: "" }],
      },
    };
    this.classes = makeStyles((theme) => ({
      formControl: {
        //margin: theme.spacing(1),
        //minWidth: 120,
      },
      select: {
        color: "white",
        fontFamily: "Avenir",
        fontSize: "12px",
        fontWeight: 800,
        letterSpacing: "0.04em",
      },
      icon: {
        fill: "white",
      },
      input: {
        minHeight: "unset",
      },
      menuItem: {
        color: "#17181D",
        fontFamily: "Avenir",
        fontSize: "12px",
        fontWeight: 800,
        letterSpacing: "0.04em",
      },
    }));
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);

    let menubar = document.getElementsByClassName("menubar");

    TM.fromTo(
      menubar,
      1,
      { opacity: 0 },
      {
        opacity: 1,
        ease: Power2.easeOut,
        delay: 1,
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.body) {
      this.setState({ pageData: { ...nextProps.body.data } });
    }
    if (nextProps.activeDocMeta) {
      this.setState({
        activeDocMeta: {
          lang: nextProps.activeDocMeta.lang,
          alternateLanguages: [...nextProps.activeDocMeta.alternateLanguages],
        },
      });
    }
  }

  setShowOnboardingModal = (val) => {
    this.setState({ showOnboardingModal: val });
  };

  handleLangChange = (event) => {
    switch (event.target.value) {
      case this.state.pageData.solutions[0].solution_name.text:
        this.context.navigateClickCallback(11);
        break;
      case this.state.pageData.solutions[1].solution_name.text:
        this.context.navigateClickCallback(12);
        break;
      case this.state.pageData.solutions[2].solution_name.text:
        this.context.navigateClickCallback(13);
        break;
      case this.state.pageData.products[0].product_name.text: // SMART WEALTH
        window.location.replace(
          "https://app.smartwealth.ch/de/dashboard/portfolios"
        );
        break;
      case this.state.pageData.products[1].product_name.text:
        this.context.navigateClickCallback(15); // 15 - 3iq
        break;
      case this.state.pageData.aboutus[0].aboutus_name.text:
        this.context.navigateClickCallback(2); // 2 - about us
        break;
      case this.state.pageData.aboutus[1].aboutus_name.text:
        this.context.navigateClickCallback(14); // 14 - brochures
        break;
      default:
        break;
    }
  };

  render() {
    const solutionOptions = this.state.pageData.solutions?.map((solution) => {
      return (
        <MenuItem
          value={solution.solution_name.text}
          className={this.classes.menuItem}
          disableUnderline={true}
        >
          {solution.solution_name.text}
        </MenuItem>
      );
    });
    const aboutUsOptions = this.state.pageData.aboutus?.map((about) => {
      return (
        <MenuItem
          value={about.aboutus_name.text}
          className={this.classes.menuItem}
          disableUnderline={true}
        >
          {about.aboutus_name.text}
        </MenuItem>
      );
    });
    const productsOptions = this.state.pageData.products?.map((product) => {
      return (
        <MenuItem
          value={product.product_name.text}
          className={this.classes.menuItem}
          disableUnderline={true}
        >
          {product.product_name.text}
        </MenuItem>
      );
    });
    return (
      <div
        className="menubar"
        style={{ height: this.state.showOnboardingModal ? "100vh" : "auto" }}
      >
        <div className="menubar__inner">
          <div
            onClick={() =>
              this.setState({
                showOnboardingModal: !this.state.showOnboardingModal,
              })
            }
            className="menubar__inner_wrapper"
          >
            <div className="button menubar__inner_wrapper_button">
              <p className="menubar__inner_wrapper_button_p">
                {this.state.pageData.button.text}
              </p>
            </div>
          </div>
          <span onClick={() => this.context.navigateClickCallback(0)}>
            {this.state.pageData.item1.text}
          </span>

          {/* <span onClick={() => this.context.navigateClickCallback(11)}>{this.state.pageData.item2.text}</span> */}
          <InputLabel variant="standard" id="solutions-selector-label">
            {this.state.pageData.item2.text}
          </InputLabel>
          <Select
            className={this.classes.select}
            labelId="solutions-selector-label"
            id="solutions-selector"
            value={""}
            label={this.state.pageData.item2.text}
            onChange={this.handleLangChange}
            disableUnderline={true}
            displayEmpty
            inputProps={{
              classes: {
                icon: this.classes.icon,
                root: this.classes.input,
              },
              "aria-label": "Without label",
            }}
          >
            {solutionOptions}
          </Select>
          <InputLabel variant="standard" id="solutions-selector-label">
            {this.state.pageData.item7.text}
          </InputLabel>
          <Select
            className={this.classes.select}
            labelId="solutions-selector-label"
            id="solutions-selector"
            value={""}
            label={this.state.pageData.item7.text}
            onChange={this.handleLangChange}
            disableUnderline={true}
            displayEmpty
            inputProps={{
              classes: {
                icon: this.classes.icon,
                root: this.classes.input,
              },
              "aria-label": "Without label",
            }}
          >
            {productsOptions}
          </Select>
          <InputLabel variant="standard" id="solutions-selector-label">
            {this.state.pageData.item3.text}
          </InputLabel>
          <Select
            className={this.classes.select}
            labelId="solutions-selector-label"
            id="solutions-selector"
            value={""}
            label={this.state.pageData.item3.text}
            onChange={this.handleLangChange}
            disableUnderline={true}
            displayEmpty
            inputProps={{
              classes: {
                icon: this.classes.icon,
                root: this.classes.input,
              },
              "aria-label": "Without label",
            }}
          >
            {aboutUsOptions}
          </Select>
          <span onClick={() => this.context.navigateClickCallback(5)}>
            {this.state.pageData.item6?.text}
          </span>
          <span onClick={() => this.context.navigateClickCallback(4)}>
            {this.state.pageData.item5.text}
          </span>
        </div>
        <OnboardingModalComponent
          showOnboardingModal={this.state.showOnboardingModal}
          setShowOnboardingModal={this.setShowOnboardingModal}
        />
      </div>
    );
  }
}

MenuBar.contextType = ContextProviderComponent;
export default MenuBar;
